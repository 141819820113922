export const environment = {
    production: true,
    comingSoonMode: false,
    speakers: true,
    workshops: false,
    apiBaseUrl: "https://admin-tedxunipi-2019.herokuapp.com/api/",
    startTimes: {
        session1: new Date(0, 0, 0, 11, 30),
        session2: new Date(0, 0, 0, 13, 45),
        session3: new Date(0, 0, 0, 16, 0),
        session4: new Date(0, 0, 0, 18, 15)
    }
};
